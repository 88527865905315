import { Box, Container } from "@mui/material"
import { makeStyles } from "makeStyles"
import Image from "next/image"
import Button from "components/Buttons/Button"
import Link from "components/i18n/Link"

import BackgroundImage from "/images/404-illustration.png"

const useStyles = makeStyles()(() => ({
	row: {
		display: "flex",
		flexFlow: "row wrap",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	leftItem: {
		flexBasis: "48%",
		width: "100%",
		padding: "10px",
		boxSizing: "border-box",
		zIndex: 1,
	},
	rightItem: {
		flexBasis: "48%",
		width: "100%",
	},
	button: {
		maxWidth: 150,
	},
	image: {
		position: "absolute",
		bottom: 0,
		right: 0,
		height: "auto",
		width: "40vw",
		zIndex: 0,
		opacity: 1,
	},

	"@media only screen and (min-width: 960px) and (max-width: 1260px)": {
		image: {
			opacity: 0.5,
			width: "70vw",
		},
	},

	"@media only screen and (max-width: 960px)": {
		image: {
			opacity: 0.5,
			width: "90vw",
		},
		leftItem: {
			marginRight: 30,
			flexBasis: "100%",
		},
		rightItem: {
			textAlign: "center",
			flexBasis: "100%",
		},
		button: {
			maxWidth: "100%",
			margin: "auto",
		},
	},
}))

const NotFoundPage = () => {
	const { classes } = useStyles()

	return (
		<div
			style={{
				background: "#042C33",
				color: "#fff",
				minHeight: 500,
				position: "relative",
				padding: "50px 0px",
			}}
		>
			<div className={classes.image}>
				<Image
					alt="404"
					src={BackgroundImage}
					quality={100}
					sizes="100vw"
					style={{
						width: "100%",
						height: "auto",
						objectFit: "contain",
					}}
				/>
			</div>
			<Container>
				<div className={classes.row}>
					<div className={classes.leftItem}>
						<div
							style={{
								textTransform: "uppercase",
								fontSize: "8rem",
								color: "whitesmoke",
								fontWeight: 800,
								marginBottom: 0,
							}}
						>
							404
						</div>
						<p style={{ fontSize: "2rem", margin: 0 }}>Seite nicht gefunden</p>
						<p>
							Es tut uns leid, die von Ihnen gewünschte Seite konnte leider nicht
							gefunden werden.
						</p>
						<Box mt={4} className={classes.button}>
							<Link href="/">
								<Button color="primary">Zur Startseite</Button>
							</Link>
						</Box>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default NotFoundPage
